<template>
  <div style="background: #f8fcff;">
    <div class="top">
      <div class="logo">
        <div style="display: flex;">
          <img class="img1" src="/img/homeIcon/titleLog.jpg" />
        </div>
        <div class="top-title">
          <p class="p1">收银台</p>
        </div>
        <div class="top-title-imgmodel">
          <img class="img2" src="/img/paytit.png" />
        </div>
      </div>
    </div>
    <div class="info">
      <div class="infoLeft">
        <p style="color: #333333;">订单提交成功，请尽快付款！</p>
        <!-- <p>订单编号：{{orderId}}</p> -->
        <p>订单类型：{{ title }}</p>
      </div>
      <div class="infoRight">
        <p>
          应付金额：<span class="body-middle-font">￥{{ price }}</span>
        </p>
        <p>
          请您在
          <span style="color: #CA1C26;">{{ minute }}:{{ second }}</span> 内支付
        </p>
      </div>
    </div>
    <el-card class="box-card">
      <div class="payImg">
        <img src="../../../public/img/vx.png" alt="" />
        <img src="../../../public/img/tuijian.png" alt="" />
      </div>
      <div class="ewm"><img :src="src" /></div>
      <div class="sao"><img src="../../../public/img/vxpay.png" /></div>
    </el-card>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  name: "",
  components: {},
  data() {
    return {
      title: "",
      price: "",
      src: "",
      orderId: "",
      minutes: 15,
      seconds: 0,
      setTimeout1: null,
    };
  },
  watch: {
    second: {
      handler(newVal) {
        this.num(newVal);
      },
    },
    minute: {
      handler(newVal) {
        this.num(newVal);
      },
    },
  },
  computed: {
    second: function () {
      return this.num(this.seconds);
    },
    minute: function () {
      return this.num(this.minutes);
    },
    ...mapState({
      get_OrderWxPay: (state) => {
        return state.pay.get_OrderWxPay;
      },
      get_OrderCancel: (state) => {
        return state.pay.get_OrderWxPay;
      },
      get_WxPayStatus: (state) => {
        return state.pay.get_OrderWxPay;
      },
    }),
  },
  mounted() {
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.back, false);
    }
    var id = this.$route.query.id;
    var that = this;
    that.$store.dispatch("pay/OrderWxPay", { id: id }).then(() => {
      var data = that.get_OrderWxPay;
      that.title = data.title;
      that.price = data.price;
      that.src = data.data;
      that.orderId = data.orderId;
      that.lunxun();
      that.add();
    });
  },
  methods: {
    back() {
      clearTimeout(this.setTimeout1);
    },
    num: function (n) {
      return n < 10 ? "0" + n : "" + n;
    },
    // 关闭页面
    closeCurrentPage() {
      const ua = window.navigator.userAgent;
      if (ua.indexOf("MSIE") > 0) {
        if (ua.indexOf("MSIE 6.0") > 0) {
          window.opener = null;
          window.close();
        } else {
          window.open("", "_top");
          window.top.close();
        }
      } else {
        window.opener = null;
        let blank = window.open("/", "_top");
        blank.close();
      }
    },
    add() {
      var _this = this;
      var time = window.setInterval(function () {
        if (_this.seconds === 0 && _this.minutes !== 0) {
          _this.seconds = 59;
          _this.minutes -= 1;
        } else if (_this.minutes === 0 && _this.seconds === 0) {
          _this.seconds = 0;
          window.clearInterval(time);
          clearTimeout(_this.setTimeout1);
          _this.closeCurrentPage();
          _this.$store
            .dispatch("pay/OrderCancel", { Id: _this.orderId })
            .then(() => {
              if (_this.get_OrderCancel.success) {
                _this.$router.push({
                  path: "/personalCenter/personalCenter",
                  query: {
                    value: 2,
                  },
                });
              }
            });
        } else {
          _this.seconds -= 1;
        }
      }, 1000);
    },
    lunxun() {
      var that = this;
      that.setTimeout1 = setTimeout(function () {
        that.WxPayStatus();
      }, 10000);
    },
    WxPayStatus() {
      var that = this;
      that.$store.dispatch("pay/WxPayStatus", { id: that.orderId }).then(() => {
        if (that.get_WxPayStatus.success) {
          that.$router.push({
            path: "/SuccessfulTrade/SuccessfulTrade",
            query: {
              orderId: that.orderId,
            },
          });
        } else {
          that.lunxun();
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.top-title {
  margin: 0 0 0 10px; /*no*/
  display: flex;
}
.top-title-imgmodel {
  margin: 0 0 0 20px; /*no*/
  display: flex;
  padding-top: 5px; /*no*/
}
.body-middle-font {
  color: #fc8208;
  font-size: 24px; /*no*/
  font-weight: 600;
}
.box-card {
  width: 1200px; /*no*/
  margin: 20px auto; /*no*/
  padding-bottom: 100px; /*no*/
}
.top {
  padding: 0 17.8% 1% 17.8%;
  display: flex;
  justify-content: space-between;
  background-color: white;

  .logo {
    display: flex;

    .img1 {
      height: 85px; /*no*/
      align-self: center;
    }
    .img2 {
      width: 319px; /*no*/
      height: 22px; /*no*/
      align-self: center;
    }
    .p1 {
      font-size: 32px; /*no*/
      font-family: SourceHanSansCN;
      font-weight: 400;
      color: #000000;
      align-self: center;
    }
    .p2 {
      font-size: 24px; /*no*/
      font-family: FZSTK;
      font-weight: normal;
      color: #fc8208;
    }
  }

  .login {
    font-size: 14px; /*no*/
    color: #7a8391;
    display: flex;
    .first {
      margin-left: 90px; /*no*/
      // margin-bottom: 8px;
      align-self: center;
      > span:nth-child(1) {
        border-right: 1px solid rgba(172, 182, 198, 0.5); /*no*/
        float: left;
      }

      > span:nth-child(2) {
        border-right: 1px solid rgba(172, 182, 198, 0.5); /*no*/
        float: left;
      }
    }
  }
}
.info {
  display: flex;
  justify-content: space-between;
  font-size: 16px; /*no*/
  padding: 20px; /*no*/
  background: url(../../../public/img/paybg.png);
  background-repeat: no-repeat;
  width: 1200px; /* no */
  margin: 20px auto; /*no*/
  .infoLeft {
    align-self: center;
    padding-left: 10px; /*no*/
    color: #666666;
    p {
      line-height: 30px; /*no*/
    }
  }
  .infoRight {
    align-self: center;
    padding-right: 10px; /*no*/
    color: #666666;
    p {
      line-height: 30px; /*no*/
    }
  }
}
.payImg {
  display: flex;
  img {
    align-self: center;
    margin-right: 10px; /*no*/
  }
}
.ewm {
  margin: 0 auto;
  margin-top: 90px; /*no*/
  width: 293px; /*no*/
  height: 293px; /*no*/
  img {
    width: 100%;
    height: 100%;
  }
}
.sao {
  margin: 0 auto;
  margin-top: 34px; /*no*/
  width: 293px; /*no*/
  height: 106px; /*no*/
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
